<template>
  <div ref="bscroll" class="home-page">
    <!-- 用户名 id 时间 金额  -->
    <user-info></user-info>
    <LiveRedPacket v-if="isRedBagSwitch === 1" />
    <red-activity :activity="activity"></red-activity>
    <task-list :tab-index="tabIndex" :red-data="redData"></task-list>
    <!-- <follow-wx :wechat_account="wechat_account"></follow-wx> -->
    <!-- <service-page></service-page> -->
  </div>
</template>
<script>
import { UserInfo, TaskList } from '@/components';
import RedActivity from '@/components/RedActivity/RedActivity';
import LiveRedPacket from '@/components/LiveRedPacket';
// import FollowWx from "@/components/FollowWx/FollowWx";
// import ServicePage from '@/components/ServicePage/ServicePage'
// import { loginInfo } from '@/api/rolelogin';
import { getRedList, redConfig } from '@/api/redList';
// import { getGameConfig } from '@/api/gameConfig';
import { getRedBagSwitch } from '@/api/redBag';
import { getDeviceType } from '@/utils';
export default {
  name: 'HomePage',
  components: {
    UserInfo,
    TaskList,
    RedActivity,
    LiveRedPacket,
    // FollowWx,
    // ServicePage
  },
  data() {
    return {
      tabIndex: 0,
      redList: [],
      redData: null,
      activity: {},
      wechat_account: '',
      isRedBagSwitch: 0,
    };
  },
  created() {
    // let that = this
    window.android ? window.android.callGetAppkey() : '';
  },
  mounted() {
    console.log('子组件mounted触发');
    console.log('缓存参数', window.sessionStorage);
    this.$bus.$on('tabClick', (index) => {
      this.tabIndex = index;
    });
    // 获取红包配置
    this.httpGetRedConfig();
    // 获取游戏配置
    // this.httpGetGameConfig();
    //获取红包入口配置
    this.getRedBagStatus();

    // 获取角色信息
    // this.httpGetLoginInfo()
    // 获取红包列表
    // this.httpGetRedList()
  },
  // components: {
  //   UserInfo,
  //   TaskList,
  //   RedActivity,
  //   LiveRedPacket,
  //   // FollowWx,
  //   // ServicePage
  // },
  methods: {
    // httpGetLoginInfo() {
    //   loginInfo({
    //     app_id: window.sessionStorage.getItem('appid'),
    //     channel_id: 1001,
    //     role_id: 1001,
    //     role_name: 1001,
    //     server_id: 1001,
    //     server_name: 1001,
    //     level: 101,
    //   }).then((res) => {
    //     console.log(res.data);
    //   });
    // },
    // 获取红包列表
    httpGetRedList() {
      getRedList({
        app_id: window.sessionStorage.getItem('appid'),
        token: window.sessionStorage.getItem('ato'),
      })
        .then((res) => {
          if (res.data.status === 200) {
            this.redData = res.data;
          } else {
            this.$toast(res?.data?.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取红包配置
    httpGetRedConfig() {
      const app_id = window.sessionStorage.getItem('appid');
      const token = window.sessionStorage.getItem('ato');
      if (app_id && token) {
        redConfig({
          app_id: window.sessionStorage.getItem('appid'),
          token: window.sessionStorage.getItem('ato'),
        }).then((res) => {
          console.log('红包配置', res);
          if (res.data.status === 200) {
            this.redConfigList = res?.data;
            this.activity = res?.data?.data;
          } else {
            this.$toast(res?.data?.msg);
          }
        });
      }
    }, //获取红区需求打开状态
    getRedBagStatus() {
      const app_id = window.sessionStorage.getItem('appid');
      const token = window.sessionStorage.getItem('ato');
      const channel_id = window.sessionStorage.getItem('channel_id');
      const pf = window.sessionStorage.getItem('pf');
      if (app_id && token && channel_id) {
        getRedBagSwitch({ app_id, token, channel_id, os: getDeviceType(), pf }).then((res) => {
          if (res.data.status === 200) {
            this.isRedBagSwitch = res.data.data.on;
          }
        });
      }
    },
    // // 获取游戏配置信息
    // httpGetGameConfig() {
    //   const app_id = window.sessionStorage.getItem('appid');
    //   const token = window.sessionStorage.getItem('ato');
    //   const channel_id = window.sessionStorage.getItem('channel_id');
    //   const pf = window.sessionStorage.getItem('pf');
    //   if (app_id && token && channel_id) {
    //     getGameConfig({
    //       app_id,
    //       token,
    //       channel_id,
    //       os: getDeviceType(),
    //       pf,
    //     }).then((res) => {
    //       if (res.data.status === 200) {
    //         this.wechat_account = res?.data?.data?.wechat_account;
    //       } else {
    //         this.$toast(res?.data?.msg);
    //       }
    //     });
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
.home-page {
  padding: 0.54rem 0.3rem;
  background-color: #f7f7f7;
  min-height: 100vh;
}
</style>
