<template>
  <div class="red-list">
    <template v-if="tabIndex == 0 && redList[0].activity_list">
      <div class="levelRed">
        <template v-if="redList[0] && Array.isArray(redList[0].activity_list) && redList[0].activity_list.length > 0">
          <div v-for="(item, index) in redList[0].activity_list" :key="index" class="list">
            <!-- <div class="list" v-for="(item, index) in []"  :key="index"> -->
            <div :class="['red-img', item.activity_rule_type]">
              <!-- <img src="@/assets/imgs/red.png" alt="" /> -->
            </div>
            <div class="red-text">
              <h3>{{ item.title }}</h3>
              <p class="red-content" v-html="item.content"></p>
              <!-- <p class="red-content">群殴闻鸡起舞我偶尔加强欧文我及饿哦完全金额 权威IE就去我我解耦我为契机饿哦几千万气温将诶哦亲危机诶哦亲为奇偶IE几千万偶尔窝气群文件诶哦权威我偶尔加强我饿</p> -->
              <p v-if="item.is_limit" class="red-limit">
                <!-- <span class="istant">即时提现</span> -->
                <span v-if="item.is_limit == 1" class="limited">限量 </span>
                <span v-if="item.limit_count != 0" class="text"
                  >剩余个数<span class="red">{{ item.limit_surplus }}</span
                  >/{{ item.limit_count }}</span
                >
              </p>
            </div>
            <div class="red-status" @click="receivePackage(item)">
              <!-- <span class="red-time">剩余:19天</span> -->
              <span v-if="item.status === 1" class="red-time">{{ item.status_text }}</span>
              <span v-if="item.status === 2" class="red-suc">{{ item.status_text }}</span>
              <img v-if="item.status === 3" src="@/assets/imgs/received.png" alt="" />
              <img v-if="item.status === 4" src="@/assets/imgs/finished.png" alt="" />
              <span v-if="item.status === 5" class="red-time">{{ item.status_text }} </span>
            </div>
          </div>
        </template>
        <div v-else class="tip">暂无活动</div>
        <!-- <div class="list">
                <div class="red-img">
                    <img src="@/assets/imgs/red.png" alt="">
                </div>
                <div class="red-text">
                    <h3>50元</h3>
                    <p>注册即送，奖励88元（新手专享）注册即送，奖励88元（新手专享）注册即送）</p>
                </div>
                <div class="red-status">
                    <span class="red-time">剩余:19天</span>
                </div>
            </div> -->
      </div>
    </template>
    <template v-if="tabIndex == 1 && Array.isArray(redList[1].activity_list) && redList[1].activity_list">
      <div class="levelRed">
        <template v-if="redList[1] && Array.isArray(redList[1].activity_list) && redList[1].activity_list.length > 0">
          <div v-for="(item, index) in redList[1].activity_list" :key="index" class="list">
            <div :class="['red-img', item.activity_rule_type]">
              <!-- <img src="@/assets/imgs/red.png" alt="" /> -->
            </div>
            <div class="red-text">
              <h3>{{ item.title }}</h3>
              <p class="red-content" v-html="item.content"></p>
              <p v-if="item.is_limit" class="red-limit">
                <!-- <span class="istant">即时提现</span> -->
                <span v-if="item.is_limit == 1" class="limited">限量 </span>
                <span v-if="item.limit_count != 0" class="text"
                  >剩余个数<span class="red">{{ item.limit_surplus }}</span
                  >/{{ item.limit_count }}</span
                >
              </p>
            </div>
            <div class="red-status" @click="receivePackage(item)">
              <!-- <span class="red-time">剩余:19天</span> -->
              <span v-if="item.status === 1" class="red-time">{{ item.status_text }} </span>
              <span v-if="item.status === 2" class="red-suc">{{ item.status_text }} </span>
              <img v-if="item.status === 3" src="@/assets/imgs/received.png" alt="" />
              <img v-if="item.status === 4" src="@/assets/imgs/finished.png" alt="" />
              <span v-if="item.status === 5" class="red-time">{{ item.status_text }} </span>
            </div>
          </div>
        </template>
        <div v-else class="tip">暂无活动</div>
        <!-- <div class="list">
                <div class="red-img">
                    <img src="@/assets/imgs/red.png" alt="">
                </div>
                <div class="red-text">
                    <h3>50元</h3>
                    <p>注册即送，奖励88元（新手专享）注册即送，奖励88元（新手专享）注册即送）</p>
                </div>
                <div class="red-status">
                    <span class="red-time">剩余:19天</span>
                </div>
            </div> -->
      </div>
    </template>
    <!-- <div v-if="tabIndex == 1" class="levelRed">
        <div class="list" v-for="(item, index) in redList[1].activity_list"  :key="index">
            <div class="red-img">
                <img src="@/assets/imgs/red.png" alt="">
            </div>
            <div class="red-text">
                <h3>{{item.title}} </h3>
                <p>{{item.content}}</p>
            </div>
            <div class="red-status">
                <img src="@/assets/imgs/received.png" alt="">
            </div>
        </div>
    </div> -->
    <Loading v-if="isReveiveRed" class="loading-box" type="spinner" />
  </div>
</template>

<script>
import { getRedPackage } from '@/api/redList';
import { Loading, Dialog } from 'vant';
export default {
  name: 'RedList',
  components: {
    Loading,
  },
  props: {
    tabIndex: Number,
    redList: Array,
  },
  data() {
    return {
      isReveiveRed: false,
    };
  },
  methods: {
    receivePackage(item) {
      if ([1, 3, 4, 5].includes(Number(item.status))) return;
      //   if (item.status == 3 || item.status == 4) return;
      console.log('红包领取状态', this.isReveiveRed);
      if (this.isReveiveRed) return;
      this.isReveiveRed = true;
      setTimeout(() => {
        this.httpGetRedPackage(item);
        this.$bus.$emit('getUserInfoEvent');
        this.$bus.$emit('getRedPackageList');
      }, 1000);
    },
    httpGetRedPackage(data) {
      getRedPackage({
        app_id: window.sessionStorage.getItem('appid'),
        activity_id: data.activity_id,
        activity_rule_id: data.activity_rule_id,
        activity_rule_type: data.activity_rule_type,
        token: window.sessionStorage.getItem('ato'),
      })
        .then((res) => {
          if (res.data.status === 200) {
            // console.log("res123", res.data)
            // this.redList = res.data.data.list
            const index = this.redList[this.tabIndex].activity_list.findIndex((e) => e.activity_rule_id === data.activity_rule_id);
            if (index > -1) {
              this.redList[this.tabIndex].activity_list[index].status = 3;
            }
            this.$emit('getRedInfo');
            this.$bus.$emit('getUserInfoEvent');
            this.$toast(res.data.msg, { duration: 1500 });
          } else {
            this.$toast(res.data.msg, { duration: 1500 });

            // console.log(res.data)
          }
          this.isReveiveRed = false;
        })
        .catch((err) => {
          console.log('err', err);
          this.isReveiveRed = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.red-list {
  margin-top: 0.4rem;
  .levelRed {
    .list {
      display: flex;
      align-items: center;
      margin-top: 0.14rem;
      padding-bottom: 0.2rem;
      border-bottom: 0.02rem solid #ddd9d9;
      &:last-child {
        border: none;
      }
      // justify-content: center;
      .red-img {
        width: 0.77rem;
        height: 0.77rem;
        margin-right: 0.2rem;
        background-image: url('../../assets/imgs/chongfan.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        &.repay {
          background-image: url('../../assets/imgs/chongfan.png');
        }
        &.pay {
          background-image: url('../../assets/imgs/chongzhi.png');
        }
        &.login {
          background-image: url('../../assets/imgs/denglu.png');
        }
        &.level {
          background-image: url('../../assets/imgs/dengji.png');
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .red-text {
        // width: 95%;
        flex: 1;
        margin-right: 0.2rem;
        h3 {
          font-size: 0.3rem;
          font-weight: 500;
        }
        .red-content {
          // width: 4.4rem;
          word-wrap: break-word;
          font-size: 0.2rem;
          color: #a7a7a7;
          margin-top: 0.06rem;
          margin-bottom: 0.06rem;
        }
        .red-limit {
          .istant {
            background-color: #3eb678;
            color: #fff;
            font-size: 0.22rem;
            padding: 0.04rem 0.04rem 0.04rem 0.06rem;
            border-radius: 0.06rem;
            margin-right: 0.1rem;
          }
          .limited {
            background-color: #ff5152;
            color: #fff;
            font-size: 0.22rem;
            padding: 0.04rem 0.08rem 0.04rem 0.08rem;
            border-radius: 0.06rem;
            margin-right: 0.1rem;
            text-align: center;
            display: inline-block;
          }
          .text {
            font-size: 0.22rem;
            .red {
              color: red;
            }
          }
        }
      }
      .red-status {
        width: 1.2rem;
        .red-time {
          width: 100%;
          height: 0.4rem;
          line-height: 0.4rem;
          text-align: center;
          display: block;
          font-size: 0.24rem;
          padding: 0.02rem 0.04rem 0.02rem 0.04rem;
          color: #a7a7a7;
          border-radius: 0.5rem;
          border: 0.02rem solid #a7a7a7;
        }
        .red-suc {
          width: 100%;
          height: 0.4rem;
          line-height: 0.4rem;
          background: #ff5152;
          // background: #96dd54;
          text-align: center;
          display: block;
          font-size: 0.24rem;
          padding: 0.02rem 0.04rem 0.02rem 0.04rem;
          // color: #312e2e;
          color: #fff;
          border-radius: 0.5rem;
          // border: 0.02rem solid #807c7c;
        }
        img {
          width: 100%;
        }
      }
    }
  }
}

.tip {
  text-align: center;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  font-size: 0.24rem;
}
.loading-box {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>
