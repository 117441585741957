<template>
  <div>
    <div v-if="redData.remaining_amount" class="task-list">
      <div class="money-time">
        <p>
          <span class="money-value">{{ redData.remaining_amount }}</span
          ><span class="money-text">可领奖金</span>
        </p>
        <p v-if="time" class="time">
          <span v-if="time !== '活动已经结束'">剩余领取时间</span>
          <span>{{ time }}</span>
        </p>
        <span class="iconfont icon-tishi" @click="toActivityRules"></span>
      </div>
      <red-page-notice />
      <tab-control :titles="titles"></tab-control>
      <red-list :tab-index="tabIndex" :red-list="redData.list" @getRedInfo="httpGetRedList"></red-list>
    </div>
    <div v-if="!redData.remaining_amount" class="tip">请进入活动区服选择角色</div>
  </div>
</template>

<script>
import TabControl from '@/components/TabControl/TabControl';
import RedList from '@/components/RedList/RedList';
import { getRedList } from '@/api/redList';
import RedPageNotice from '../RedPageNotice/index.vue';

export default {
  name: 'TaskList',
  components: { TabControl, RedList, RedPageNotice },
  props: {
    tabIndex: Number,
  },
  data() {
    return {
      redData: {},
      titles: [],
      time: '',
      countTimeObj: {},
      endTime: null,
    };
  },
  created() {
    // 获取红包列表
    this.httpGetRedList();
    // this.countTime(1608393600)
  },
  // mounted
  methods: {
    // 获取红包列表
    httpGetRedList() {
      const appid = window.sessionStorage.getItem('appid');
      const token = window.sessionStorage.getItem('ato');
      if (appid && token) {
        getRedList({
          app_id: window.sessionStorage.getItem('appid'),
          token: window.sessionStorage.getItem('ato'),
        })
          .then((res) => {
            if (res.data.status === 200) {
              this.redData = res.data.data;
              this.titles.splice(0, this.titles.length, ...this.redData.list);
              if (res.data.data.end_time) {
                this.endTime = Date.parse(new Date(res.data.data.end_time * 1000));
                // 倒计时
                this.countdown();
              }
            } else {
              this.$toast(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    toActivityRules() {
      // this.$router.push({ name: 'ActivityRules', params: { redData: this.redData } });
      this.$router.push({
        path: '/activityRules',
        query: { redData: this.redData?.rule_content || '' },
      });
    },
    countdown() {
      var that = this;
      // 目标日期时间戳
      const end = this.endTime;
      // 当前时间戳
      const now = Date.parse(new Date());
      // 相差的毫秒数
      const msec = end - now;
      // 计算时分秒数
      let day = parseInt(msec / 1000 / 60 / 60 / 24);
      let hr = parseInt((msec / 1000 / 60 / 60) % 24);
      let min = parseInt((msec / 1000 / 60) % 60);
      let sec = parseInt((msec / 1000) % 60);
      // 个位数前补零
      hr = hr > 9 ? hr : '0' + hr;
      min = min > 9 ? min : '0' + min;
      sec = sec > 9 ? sec : '0' + sec;
      // 控制台打印
      if (new Date().getTime() > this.endTime) {
        this.time = '活动已经结束';
        return;
      }
      this.time = `${day}天 ${hr}: ${min}: ${sec}秒`;
      // 一秒后递归
      setTimeout(function () {
        that.countdown();
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.tip {
  margin: 0.2rem 0;
  text-align: center;
  font-size: 0.32rem;
  color: rgb(128, 128, 128);
}
.task-list {
  // margin-top: 0.08rem;
  background-color: #fff;
  border-radius: 0.05rem;
  padding: 0.2rem 0.4rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  .tip {
    text-align: center;
    font-size: 0.32rem;
    color: rgb(128, 128, 128);
  }

  .money-time {
    position: relative;
    .icon-tishi {
      top: 0.2rem;
      right: 0.1rem;
      font-size: 0.3rem;
      position: absolute;
      color: #6b6969;
    }

    .money-value {
      font-size: 0.5rem;
      font-weight: 700;
      color: #ec1515;
      margin-right: 0.2rem;
    }
    .money-text {
      color: #a7a7a7;
      font-size: 0.3rem;
    }
    .time {
      font-size: 0.2rem;
      color: #a7a7a7;
    }
  }
}
</style>
