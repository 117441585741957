<template>
  <div class="user-info">
    <div class="logo">
      <img src="@/assets/imgs/logo.png" alt="" />
    </div>
    <div class="info">
      <p class="user-id">{{ userName }}</p>
      <!-- <p><span class="user-name">{{role.role_name}}</span> <span class="user-create">({{role.level}}级/已创角{{createTime}})</span></p> -->
      <p>
        <span class="user-name">{{ role && role.role_name }}</span>
        <span class="user-create">{{ userText }}</span>
      </p>
      <p v-if="role && role.level" class="money">
        <span class="money-value"><i class="iconfont icon-purse"></i> {{ cBalance }}元</span>
        <span class="money-detail" @click="toMoneyDetail">明细</span>
        <span class="monry-withdrawal" @click="toWithdrawal">提现</span>
      </p>
    </div>
    <div class="profile">
      <span class="iconfont icon-bianji" @click="toProfile"></span>
      <span class="kefu">
        <img src="https://api.iconify.design/ri:customer-service-2-line.svg" alt="" @click="toKefu" />
      </span>
    </div>
  </div>
</template>

<script>
// import { mapState } from 'vuex'
import { getUserInfo } from '@/api/redList';
export default {
  name: 'UserInfo',
  data() {
    return {
      userName: '',
      role: {
        role_name: '',
      },
      balance: '',
      home: '',
      // createTime: ""
    };
  },
  computed: {
    // ...mapState([
    //     'count'
    // ])
    cBalance() {
      let toFixedNum = Number(this.balance).toFixed(3);
      let realVal = toFixedNum.substring(0, toFixedNum.toString().length - 1);
      return realVal;
    },
    createTime() {
      // 目标日期时间戳
      const start = this.role.created_role_time * 1000;
      // 当前时间戳
      const now = Date.parse(new Date());
      // 相差的毫秒数
      const msec = now - start;
      // 计算时分秒数
      let day = parseInt(msec / 1000 / 60 / 60 / 24);
      let hr = parseInt((msec / 1000 / 60 / 60) % 24);
      let min = parseInt((msec / 1000 / 60) % 60);
      // let time = `${day}天 ${hr}小时 ${min}分钟`
      let time = '';

      // let sec = parseInt(msec / 1000 % 60)
      // 个位数前补零
      // hr = hr > 9 ? hr : '0' + hr
      // min = min > 9 ? min : '0' + min
      // sec = sec > 9 ? sec : '0' + sec
      // 控制台打印
      // this.time = `${day}天 ${hr}: ${min}: ${sec}秒`
      if (day == 0 && hr == 0 && min < 1) {
        time = `小于1分钟`;
      } else if (hr < 1 && day == 0) {
        time = `${min}分钟`;
      } else if (hr >= 1 && day == 0) {
        time = `${hr}小时${min}分钟`;
      } else if (day >= 1) {
        time = `${day}天 ${hr}小时`;
      }

      return time;
    },
    userText() {
      if (this.role?.level) {
        return `(${this.role.level}级/已创角${this.createTime})`;
      } else {
        return '请进入活动区服选择角色';
      }
    },
  },
  mounted() {
    this.userName = window.sessionStorage.getItem('accountName');
    this.$bus.$on('getUserInfoEvent', () => {
      this.httpGetUserInfo();
    });
    this.httpGetUserInfo();
  },
  methods: {
    addition() {
      this.$store.commit('increment');
    },
    subtraction() {
      this.$store.commit('decrement');
    },
    toWithdrawal() {
      this.$router.push({ name: 'WithdrawalType' });
    },
    toMoneyDetail() {
      this.$router.push({ name: 'MoneyDetail' });
    },
    toProfile() {
      let url = this.getOriginUrl();
      if (location.hash.indexOf('?') > -1) {
        url = url + (location.search || location.hash.slice(location.hash.indexOf('?')));
      }
      window.location.href = url;
    },
    toKefu() {
      let url = this.getOriginUrl() || '';
      url = url.replace('/profile', '/serve');
      if (location.hash.indexOf('?') > -1) {
        url = url + (location.search || location.hash.slice(location.hash.indexOf('?')));
      }
      window.location.href = url;
    },
    getOriginUrl() {
      // let origin = location.origin;
      // if (origin.indexOf("192.168") > -1) {
      //   return (origin = "http://192.168.1.137:8081/#/profile");
      // }
      return this.home;
    },
    httpGetUserInfo() {
      const appid = window.sessionStorage.getItem('appid');
      const token = window.sessionStorage.getItem('ato');

      if (appid && token) {
        getUserInfo({
          app_id: window.sessionStorage.getItem('appid'),
          token: window.sessionStorage.getItem('ato'),
        })
          .then((res) => {
            if (res.data.status === 200) {
              console.log('GetUserInfo', res.data);
              this.role = res.data.data.role;
              this.balance = res.data.data.balance;
              this.userName = res.data.data.account_name;
              this.home = res.data.data.home;
            } else {
              this.$toast(res.data.msg);
            }
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
    },
  },
};
</script>

<style lang="less">
.user-info {
  // box-shadow: 0.02rem 0.0.2;
  background-color: #fff;
  position: relative;
  border-radius: 0.05rem;
  padding: 0.4rem 0.2rem 0 0.2rem;
  display: flex;
  .logo {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.1rem;
    margin-left: 0.2rem;
    background-color: aqua;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .info {
    // flex: 0.8;
    width: 4rem;
    margin-left: 0.2rem;
    .user-id {
      margin-top: 0.1rem;
      font-size: 0.4rem;
      font-weight: 600;
    }
    .user-name {
      font-size: 0.3rem;
    }
    .user-create {
      font-size: 0.24rem;
      color: #7c7777;
    }
    .money {
      margin: 0.14rem;
      .money-value {
        font-size: 0.26rem;
      }
      .icon-purse {
        font-size: 0.26rem;
      }
      .money-detail,
      .monry-withdrawal {
        color: #e25e5e;
        width: 0.6rem;
        height: 0.3rem;
        text-align: center;
        padding: 0.05rem 0.03rem;
        display: inline-block;
        font-size: 0.24rem;
        background-color: #fcf6f6;
        border-radius: 0.14rem;
        margin-left: 0.24rem;
      }
    }
  }
  .profile {
    position: absolute;
    right: 0.4rem;
    top: 0.6rem;
    display: flex;
    align-items: center;
    // margin-top: 0.1rem;
    .icon-bianji {
      width: 0.3rem;
      height: 0.3rem;
      display: inline-block;
      font-weight: 700;
      font-size: 0.3rem;
    }
    .kefu {
      width: 0.4rem;
      height: 0.4rem;
      margin-left: 0.3rem;
      img {
        width: 100%;
      }
    }
  }
}
</style>
