<template>
  <div v-if="Array.isArray(list) && list.length > 0" class="notice">
    <div class="laba"><img src="@/assets/imgs/laba.png" alt="" /></div>
    <div class="notice-txt">
      <ul class="marquee_list" :style="{ top: listTop + 'px' }">
        <li v-for="(item, index) in list" :key="index">{{ item }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { randomString } from '@/utils/index';
export default {
  data() {
    return {
      list: [],
      listTop: 0,
    };
  },
  created() {
    this.list.push(this.setText());
    this.list.push(this.setText());
  },
  mounted() {
    this.setScroll();
  },
  methods: {
    setScroll() {
      var t = setInterval(() => {
        this.listTop -= 1;
        if (this.listTop === -37) {
          this.list.splice(0, 1);
          this.list.push(this.setText());
          // this.list.push(this.list.splice(0, 1)[0]);
          this.listTop = 0;
          clearInterval(t);
          setTimeout(() => {
            this.setScroll();
          }, 2000);
        }
      }, 20);
    },
    setText() {
      let s1 = randomString(4);
      let s2 = randomString(4);
      let text = `恭喜玩家[u${s1}***${s2}]提现成功！`;
      return text;
    },
  },
};
</script>

<style lang="less" scoped>
.notice {
  display: flex;
  align-items: center;
  margin-top: 0.1rem;
  padding: 0.08rem 0.2rem;
  .laba {
    width: 0.4rem;
    img {
      width: 100%;
    }
  }
  .notice-txt {
    height: 0.7rem;
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .marquee_list {
    top: 0;
    position: absolute;

    margin-left: 0.3rem;
    list-style: none;
    li {
      font-size: 12px;
      color: #ff4256;
      height: 0.7rem;
      line-height: 0.7rem;
    }
  }
}
</style>
