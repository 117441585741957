<template>
  <div class="red-activity">
    <!-- <h3>红包活动</h3> -->
    <div class="activity">
      <div>
        <p>
          <span class="red-text">参与玩家</span>
          <span class="red-value">{{ activity.activity_people_num }}</span>
        </p>
        <!-- <p class="red-value">{{33333}}人</p> -->
        <!-- <p class="red-value">参与玩家{{activity.activity_people_num}}人</p> -->
        <!-- <p class="red-text"></p> -->
      </div>
      <div>
        <p>
          <span class="red-text">已发奖金</span>
          <span class="red-value">{{ activity.activity_total_amount }}</span>
        </p>
        <!-- <p class="red-value">已发奖金{{activity.activity_total_amount}}元</p> -->
        <!-- <p class="red-text">已发奖金</p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RedActivity',
  props: {
    activity: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="less" scoped>
.red-activity {
  background-color: #fff;
  border-radius: 0.05rem;
  padding: 0.15rem 0.3rem 0.1rem 0.3rem;
  margin-top: 0.1rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  h3 {
    font-size: 0.26rem;
    font-weight: 500;
    color: #a7a7a7;
  }
  .activity {
    // margin: 0.2rem;
    display: flex;
    justify-content: center;
    text-align: center;
    div {
      width: 4rem;
    }
    .red-value {
      font-size: 0.26rem;
      font-weight: 500;
      margin-bottom: 0.06rem;
    }
    .red-text {
      margin-right: 0.08rem;
      font-size: 0.22rem;
      color: #a7a7a7;
    }
  }
}
</style>
