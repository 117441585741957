<template>
  <div class="red-container" @click="handleGoBag">
    <div class="content">
      <div class="red-info">
        <div class="red-title">直播间互动抢红包</div>
        <div class="red-text">
          <span class="text">立即去抢</span>
          <span class="text-icon"></span>
        </div>
      </div>
      <div class="red-icon"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RedActivity',
  props: {
    activity: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    handleGoBag() {
      this.$router.push({ name: 'GrabRed' });
    },
  },
};
</script>

<style lang="less" scoped>
.red-container {
  padding: 0 0 0.12rem;
  margin-top: 0.22rem;
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.1rem 0.36rem 0rem;
    background: #fefefe;
    box-shadow: 0px 2px 1px 0px rgba(200, 200, 200, 0.1);
    border-radius: 0.05rem;
    .red-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 100%;
      // padding-top: 0.1rem;
      .red-title {
        font-size: 0.32rem;
        font-weight: 500;
        color: #222222;
        line-height: 0.39rem;
        margin-bottom: 0.1rem;
      }
      .red-text {
        font-size: 0.28rem;
        font-weight: 400;
        color: #8d8d8d;
        line-height: 0.32rem;
        display: flex;
        align-items: center;
        .text {
        }
        .text-icon {
          margin-left: 0.08rem;
          width: 0.57rem;
          height: 0.12rem;
          background-image: url('../../assets/imgs/red-left.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }
    .red-icon {
      width: 1.03rem;
      height: 1.24rem;
      background-image: url('../../assets/imgs/live-red.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}
</style>
