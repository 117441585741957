<template>
  <div class="tab-control">
    <div v-for="(item, index) in titles" :key="index" class="tab-control-item" :class="{ active: index === curIndex }" @click="tabControlClick(index)">
      <div class="item">
        <span>{{ item.tab }}</span>
        <div v-if="item.completed_num > 0" class="num-tips">
          <div>{{ item.completed_num }}</div>
        </div>
        <div v-if="index === curIndex" class="border-bottom"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabControl',
  props: {
    tabIndex: Number,
    titles: Array,
  },
  data() {
    return {
      curIndex: 0,
    };
  },
  mounted() {
    document.body.scrollTop = 0;
  },
  methods: {
    tabControlClick(index) {
      if (index === this.curIndex) return;
      this.$bus.$emit('tabClick', index);
      this.curIndex = index;
    },
  },
};
</script>

<style lang="less" scoped>
.tab-control {
  display: flex;
  margin-top: 0.2rem;
  .tab-control-item {
    margin-right: 0.18rem;

    // text-align: center;
    // flex: 1;
    cursor: pointer;
    span {
      font-size: 0.3rem;
    }
    .item {
      position: relative;
      padding: 0.05rem 0.05rem 0.16rem 0.05rem;
      .num-tips {
        position: absolute;
        top: -0.11rem;
        right: -0.22rem;
        width: 0.3rem;
        height: 0.3rem;
        padding: 0.02rem;
        background-color: #ff6120;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        div {
          line-height: 0.3rem;
          font-size: 0.24rem;
          font-family: Source Han Sans SC;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
  }
  .active {
    color: #ec1515;
  }
  .active span {
    // border-bottom: 0.04rem solid #ec1515;
  }
  .border-bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 1rem;
    transform: translateX(-50%);
    border-bottom: 0.04rem solid #ec1515;
  }
}
</style>
